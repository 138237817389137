export default class Load {
  constructor(_parm) {
    this.elmWrapper = document.querySelector(_parm.wrapper)
    this.elmNextLinks = [...document.querySelector(_parm.wrapper).querySelectorAll(_parm.nextLinks)]
    this.elmTargets = [...document.querySelector(_parm.wrapper).querySelectorAll(_parm.targets)]
    this.elmBackBtns = [...document.querySelector(_parm.wrapper).querySelectorAll(_parm.backBtns)]
    this.dataFlagAttr = _parm.dataFlagAttr
  }
  open(_targetId) {
    this.elmTargets.some(_elmTarget => {
      if (_elmTarget.id === _targetId) {
        _elmTarget.setAttribute(this.dataFlagAttr, 'true')
      }
    });
  }
  close(_selector) {
    _selector.setAttribute(this.dataFlagAttr, 'false')
  }
  addEvent() {
    this.elmNextLinks.forEach(_elmNextLink => {
      _elmNextLink.addEventListener('click', (_ev) => {
        this.open(_ev.currentTarget.getAttribute('data-id'))
        return false;
      })
    })
    this.elmBackBtns.forEach(_elmBackBtn => {
      _elmBackBtn.addEventListener('click', (_ev) => {
        this.close(_ev.currentTarget.parentNode)
        return false;
      })
    })
  }
  init() {
    this.addEvent()
  }
}
