export default class HideMenu {
  constructor(_parm) {
    this.selector = _parm.elms
    this.elms = [...document.querySelectorAll(_parm.elms)]
  }
  show(_elm) {
    _elm.setAttribute('data-active', 'true')
  }
  hide(_elm) {
    _elm.setAttribute('data-active', 'false')
  }
  toggleDisplay(_elm) {
    if (_elm.getAttribute('data-active') === 'true') {
      this.hide(_elm)
    } else {
      this.show(_elm)
    }
  }
  addEvent() {
    this.elms.forEach(_elm => {
      _elm.addEventListener('click', (_ev) => {
        const target = _ev.currentTarget.firstElementChild
        this.toggleDisplay(target)
      })
    })
    document.addEventListener('click', (_ev) => {
      if (!_ev.target.closest(this.selector)) {
        this.elms.forEach(_elm => {
          this.hide(_elm.firstElementChild)
        })
      }
    })
  }
  init() {
    this.addEvent()
  }
}
