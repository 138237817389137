export default class Tab {
  constructor(_parm) {
    this.navAttr = _parm.navAttr
    this.contentAttr = _parm.contentAttr
    this.navs = [...document.querySelectorAll(`[${_parm.navAttr}]`)]
    this.contents = [...document.querySelectorAll(`[${_parm.contentAttr}]`)]
    this.currentClass = _parm.currentClass
  }
  show(_elm) {
    _elm.style.display = 'block'
  }
  hide(_elm) {
    _elm.style.display = 'none'
  }
  addCurrentClass(_elm) {
    _elm.classList.add(this.currentClass)
  }
  removeCurrentClass(_elm) {
    _elm.classList.remove(this.currentClass)
  }
  addEvent() {
    this.navs.forEach(_elmNav => {
      _elmNav.addEventListener('click', (_ev) => {
        _ev.preventDefault();
        const nav = _ev.currentTarget
        const navBrothers = [...nav.parentNode.children]
        const key = _ev.currentTarget.getAttribute(this.navAttr)
        const target = document.querySelector(`[${this.contentAttr}=${key}]`)
        const targetBrothers = [...target.parentNode.children]
        navBrothers.forEach(_elmNavBrother => {
          this.removeCurrentClass(_elmNavBrother)
        })
        targetBrothers.forEach(_elmTargetBrother => {
          this.hide(_elmTargetBrother)
        })
        this.show(target)
        this.addCurrentClass(nav)
      })
    })
  }
  init() {
    this.addEvent()
  }
}
