export default class Alert {
  constructor(_parm) {
    this.keyAttr = _parm.targetsAttr
    this.targets = [...document.querySelectorAll(`[${_parm.targetsAttr}]`)]
  }
  show(_elm) {
    _elm.setAttribute('data-active', 'true')
  }
  hide(_elm) {
    _elm.setAttribute('data-active', 'false')
  }
  addEvent() {
    this.targets.forEach(_elmTarget => {
      const key = _elmTarget.getAttribute(this.keyAttr)
      const elmContent = _elmTarget
      const elmOpenBtns = [...document.querySelectorAll(`[data-js-alert-open-btn=${key}]`)]
      const elmCloseBtns = [...document.querySelectorAll(`[data-js-alert-close-btn=${key}]`)]

      elmOpenBtns.forEach(_elmOpenBtn => {
        _elmOpenBtn.addEventListener('click', () => {
          this.show(elmContent)
        })
      })
      elmCloseBtns.forEach(_elmCloseBtn => {
        _elmCloseBtn.addEventListener('click', () => {
          this.hide(elmContent)
        })
      })
    })
  }
  init() {
    this.addEvent()
  }
}
