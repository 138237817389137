export default (_url, _selector) => {
  const p = new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    const selector = document.querySelector(_selector) || false
    if (!selector) return;
    xhr.open('GET', _url, true)
    xhr.addEventListener('load', (e) => resolve(xhr))
    xhr.onreadystatechange = () => {
      if(xhr.readyState === 4 && xhr.status === 200) {
        const restxt = xhr.responseText
        selector.innerHTML = restxt
      }
    }
    xhr.send()
  })
  return p;
}
