import sidebarData from './data/sidebar-data.json'

import fileLoad from './function/fileLoad'
import createSidbarNav from './function/createSidbarNav'

import SidbarNav from './SidbarNav'
import HideMenu from './HideMenu'
import Modal from './Modal'
import Tab from './Tab'
import Alert from './Alert'

const init = () => {

  const sidbarNavFunc = () => {
    const sidbarNav = new SidbarNav({
      wrapper: '#js-sidebar-nav',
      nextLinks: 'a[data-id]',
      targets: '.p-sidebar-nav-child__block',
      backBtns: '.p-sidebar-nav-child__back-btn',
      dataFlagAttr: 'data-open'
    })
    sidbarNav.init()
  }

  const responsiveHideMenuFunc = () => {
    if (!document.querySelector('.c-responsive-dropdown-list')) return;
    const responsiveHideMenu = new HideMenu({
      elms: '.c-responsive-dropdown-list'
    })
    responsiveHideMenu.init()
  }
  const propertyTableHideMenuFunc = () => {
    if (!document.querySelector('.c-hide-menu')) return;
    const propertyTableHideMenu = new HideMenu({
      elms: '.c-hide-menu'
    })
    propertyTableHideMenu.init()
  }

  const modalFunc = () => {
    if (!document.querySelector('[data-js-modal-content]')) return;
    const modal = new Modal({
      targetsAttr: 'data-js-modal-content'
    })
    modal.init()
  }

  const tabFunc = () => {
    if (!document.querySelector('[data-js-tab-nav]')) return;
    const tab = new Tab({
      navAttr: 'data-js-tab-nav',
      contentAttr: 'data-js-tab-content',
      currentClass: '__active'
    })
    tab.init()
  }

  const alertFunc = () => {
    if (!document.querySelector('[data-js-alert-content]')) return;
    const alert = new Alert({
      targetsAttr: 'data-js-alert-content'
    })
    alert.init()
  }

  fileLoad('/_modules/sidebar.html', '#js-load-sidebar')
    .then((xhr) => fileLoad('/_modules/navbar.html', '#js-load-navbar'))
    .then((xhr) => fileLoad('/_modules/footer.html', '#js-load-footer'))
    .then(() => createSidbarNav(sidebarData))
    .then(() => sidbarNavFunc())
    .then(() => responsiveHideMenuFunc())
    .then(() => propertyTableHideMenuFunc())
    .then(() => modalFunc())
    .then(() => tabFunc())
    .then(() => alertFunc())

  // fileLoad('/_modules/navbar.html', '#js-load-navbar')
  //   .then((xhr) => fileLoad('/_modules/footer.html', '#js-load-footer'))
  //   .then(() => sidbarNavFunc())

}

window.addEventListener('DOMContentLoaded', () => {
  init()
})
