export default (_data) => {
  const datas = _data.data
  const target = document.querySelector('#js-sidebar-nav')

  const crateElm = (_tag, _class, _id, _href, _dataId) => {
    let elm = document.createElement(_tag)
    _class ? elm.classList.add(_class) : false;
    _id ? elm.id = _id : false;
    _href ? elm.setAttribute('href', _href) : false;
    _dataId ? elm.setAttribute('data-id', _dataId) : false;
    return elm;
  }

  const createParent = (_dataObject) => {
    let tagItem = crateElm('li', 'p-sidebar-nav__item--parent')
    let tagLink
    if (_dataObject.child) {
      tagLink = crateElm('a', 'p-sidebar-nav__link--parent', false, 'javascript:void(0);', `level-01-${_dataObject.id}`)
    } else {
      tagLink = crateElm('a', 'p-sidebar-nav__link--parent', false, _dataObject.link)
    }
    let tagSpan = crateElm('span', 'p-sidebar-nav__text--parent')
    tagItem.appendChild(tagLink).appendChild(tagSpan).textContent = _dataObject.label
    return tagItem;
  }

  const createChild = (_dataObject, _level) => {
    let tagItem = crateElm('li', 'p-sidebar-nav__item--child')
    let tagLink
    if (_dataObject.child) {
      tagLink = crateElm('a', 'p-sidebar-nav__link--child', false, 'javascript:void(0);', `level-0${_level}-${_dataObject.id}`)
    } else {
      tagLink = crateElm('a', 'p-sidebar-nav__link--child', false, _dataObject.link)
    }
    let tagSpan = crateElm('span', 'p-sidebar-nav__text--child')
    tagSpan.textContent = _dataObject.label
    tagItem.appendChild(tagLink).appendChild(tagSpan)
    return tagItem;
  }

  let tagWrapperParent = crateElm('ul', 'p-sidebar-nav-parent', 'js-sidebar-nav-parent')
  let tagWrapperChildLevel_01 = crateElm('div', 'p-sidebar-nav-child--level-01', 'js-sidebar-nav-child-level-01')
  let tagWrapperChildLevel_02 = crateElm('div', 'p-sidebar-nav-child--level-02', 'js-sidebar-nav-child-level-02')

  for (const data in datas) {
    /**
     * Parent
     */
    const dataObject = datas[data]
    tagWrapperParent.appendChild(createParent(dataObject))

    /**
     * ChildLevel_01
     */
    if (dataObject.child) {
      let tagBlock_1 = crateElm('div', 'p-sidebar-nav-child__block', `level-01-${dataObject.id}`)
      let tagHead_1 = crateElm('p', 'p-sidebar-nav-child__head')
      tagHead_1.textContent = dataObject.label
      let tagBackBtn_1 = crateElm('a', 'p-sidebar-nav-child__back-btn', false, 'javascript:void(0);')
      tagBackBtn_1.textContent = '前のメニューに戻る'
      let tagContent_1 = crateElm('div', 'p-sidebar-nav-child__content')
      let tagLists_1 = crateElm('ul', 'p-sidebar-nav-child__lists')

      for (const dataObjectChildsLebel_01 in dataObject.child) {
        const dataObjectChildLevel_01 = dataObject.child[dataObjectChildsLebel_01]
        tagLists_1.appendChild(createChild(dataObjectChildLevel_01, '2'))

        /**
         * ChildLevel_02
         */
        if (dataObjectChildLevel_01.child) {
          let tagBlock_2 = crateElm('div', 'p-sidebar-nav-child__block', `level-02-${dataObjectChildLevel_01.id}`)
          let tagHead_2 = crateElm('p', 'p-sidebar-nav-child__head')
          tagHead_2.textContent = dataObjectChildLevel_01.label
          let tagBackBtn_2 = crateElm('a', 'p-sidebar-nav-child__back-btn', false, 'javascript:void(0);')
          tagBackBtn_2.textContent = '前のメニューに戻る'
          let tagContent_2 = crateElm('div', 'p-sidebar-nav-child__content')
          let tagLists_2 = crateElm('ul', 'p-sidebar-nav-child__lists')
          for (const dataObjectChildsLebel_02 in dataObjectChildLevel_01.child) {
            const dataObjectChildLevel_02 = dataObjectChildLevel_01.child[dataObjectChildsLebel_02]
            tagLists_2.appendChild(createChild(dataObjectChildLevel_02, '3'))
          }
          tagBlock_2.appendChild(tagHead_2)
          tagBlock_2.appendChild(tagBackBtn_2)
          tagBlock_2.appendChild(tagContent_2).appendChild(tagLists_2)
          tagWrapperChildLevel_02.appendChild(tagBlock_2)
        }
        /**
         * / End ChildLevel_02
         */
      }
      tagBlock_1.appendChild(tagHead_1)
      tagBlock_1.appendChild(tagBackBtn_1)
      tagBlock_1.appendChild(tagContent_1).appendChild(tagLists_1)
      tagWrapperChildLevel_01.appendChild(tagBlock_1)
    }
  }
  target.appendChild(tagWrapperParent)
  target.appendChild(tagWrapperChildLevel_01)
  target.appendChild(tagWrapperChildLevel_02)
}
